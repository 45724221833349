var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { label: "Enter user", prop: "userId" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      remote: "",
                      clearable: "",
                      "remote-method": _vm.getUserList,
                      name: "User",
                      placeholder: "Enter user"
                    },
                    model: {
                      value: _vm.formData.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "userId", $$v)
                      },
                      expression: "formData.userId"
                    }
                  },
                  _vm._l(_vm.userList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.fullName, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { label: "Enter Product", prop: "productId" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      remote: "",
                      clearable: "",
                      "remote-method": _vm.getProductList,
                      name: "Product",
                      placeholder: "Enter Product Name"
                    },
                    model: {
                      value: _vm.formData.productId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "productId", $$v)
                      },
                      expression: "formData.productId"
                    }
                  },
                  _vm._l(_vm.productList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Bazar Dhara Discount",
                  prop: "bazarDharaDiscount"
                }
              },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "bazarDharaDiscount",
                    precision: 2,
                    step: 1,
                    min: 0,
                    max: 100
                  },
                  model: {
                    value: _vm.formData.bazarDharaDiscount,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bazarDharaDiscount", $$v)
                    },
                    expression: "formData.bazarDharaDiscount"
                  }
                }),
                _c("span", [_vm._v(" (%)")])
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }