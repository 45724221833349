var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("users-products-discount-detail", {
    attrs: { "is-edit": _vm.isEdit }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }