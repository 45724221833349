import { render, staticRenderFns } from "./UsersProductsDiscountDetail.vue?vue&type=template&id=0be52cfc&scoped=true&"
import script from "./UsersProductsDiscountDetail.vue?vue&type=script&lang=ts&"
export * from "./UsersProductsDiscountDetail.vue?vue&type=script&lang=ts&"
import style0 from "./UsersProductsDiscountDetail.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./UsersProductsDiscountDetail.vue?vue&type=style&index=1&id=0be52cfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be52cfc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0be52cfc')) {
      api.createRecord('0be52cfc', component.options)
    } else {
      api.reload('0be52cfc', component.options)
    }
    module.hot.accept("./UsersProductsDiscountDetail.vue?vue&type=template&id=0be52cfc&scoped=true&", function () {
      api.rerender('0be52cfc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/users-products-discounts/components/UsersProductsDiscountDetail.vue"
export default component.exports